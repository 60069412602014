/**
 * 
 *  Compares strings using localeCompare but also considers enumerations inside the strings.
 *  
 *  E.g.:
 *      Project 1
 *      Project 2
 *      Project 3
 *      Project 21
 * 
 *   ... are sorted by the number inside the Project.
 *
 */
 export const comparingStringNumbered = (a, b) => {
    const aMatcher = a.match(/([^0-9]*)(\d+)([^0-9]*)?/)
    const bMatcher = b.match(/([^0-9]*)(\d+)([^0-9]*)?/)

    // Check if both strings have numbers and are equal at the beginning
    // If both are undefined (e.g. both starting directly with a number), its also considered equal
    if (aMatcher !== null && bMatcher !== null && aMatcher[1] === bMatcher[1]) {

        // Check if both have a number
        if (aMatcher[2] !== undefined && bMatcher[2] !== undefined) {
           
           const numberResult = (Number(aMatcher[2]) - Number((bMatcher[2])))
           if (numberResult !== 0) {
               return numberResult;
           }

           // Numbers are same. Compare last part if there is one.
           if (aMatcher[3] !== undefined && bMatcher[3] !== undefined) {
                return aMatcher[3].localeCompare(bMatcher[3])
           } else {
               // Otherwise the number comparison is determining
               return numberResult
           }

        }
    }

    // No numbers. Easy life :)

    return a.localeCompare(b)
}