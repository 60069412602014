import { requireValue } from "modules/picasso-modules/utils/validateUtils";
import { comparingStringNumbered } from "./compareUtils";
import { randomNumber } from "./randomUtils";
import { getFieldByPath } from "./objectUtils";

const isEmpty = (value) => {
    if (value === undefined) {
        return true;
    }
    if (value === null) {
        return true;
    }
    if (value.length === 0) {
        return true;
    }
    return false;
};

const isNotEmpty = (value) => {
    return isEmpty(value) === false;
};

export const join = (arr, separator, maxCount) => {
    if (!arr) {
        return ''
    }
    if (maxCount !== undefined && maxCount !== null) {
        return arr.slice(0,maxCount).join(separator)
    }
    return arr.join(separator)
} 

export const arrayIsEmpty = (value) => {
    if (value === undefined) {
        return true;
    }
    if (value === null) {
        return true;
    }
    if (value.length === 0) {
        return true;
    }
    return false;
};

/**
 *  Internal util method - don't export
 */
 const isString = (val) => {
    return typeof val === 'string' || val instanceof String
}

/**
 *  Internal util method - don't export
 */
const compareDeep = (a, b, property) => {
    const aValue = deep_value(a, property);
    const bValue = deep_value(b, property);

    // Handling null and undefined to be treated as lower than other values
    if (aValue == null && bValue == null) {
        return 0; // Both are null or undefined, treated as equal
    } else if (aValue == null) {
        return -1; // aValue is null or undefined, treat as lower
    } else if (bValue == null) {
        return 1; // bValue is null or undefined, treat as lower
    }

    if ((property === 'name' || property.endsWith('.name')) && isString(aValue) && isString(bValue)) {
        return comparingStringNumbered(aValue, bValue);
    }

    return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
}

export const dynamicSort = (property:string, secondaryProperty?:string) => {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    var secondarySortOrder = 1    
    if (secondaryProperty) {
        if(secondaryProperty[0] === "-") {
            secondarySortOrder = -1;
            secondaryProperty = secondaryProperty.substr(1);
        }
    }

    return function (a,b) {
        var result = compareDeep(a, b, property)

        //if sort equal, check secondaryProperty
        //or if item has a name, and sort by name as second
        if (result === 0) {
            if (secondaryProperty) {
                return compareDeep(a,b, secondaryProperty) * secondarySortOrder
            } else {
                if (a.name?.length > 0 && b.name?.length > 0) {
                    return comparingStringNumbered(a.name, b.name) * secondarySortOrder //sort order doesnt matter in this case  
                }
            }
        }

        return result * sortOrder;
    }
}

var deep_value = function(obj, path){
    if (obj === undefined || obj === null) {
        return null;
    }
    for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
        obj = obj[path[i]];
        if (obj === undefined || obj === null) {
            return null;
        }
    };
    return obj;
};

export const removeArrayItem = (arr, value) => {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
}

/**
 *  Remove duplicates without breaking order
 */
 export const removeDuplicates = (arr) => {
    requireValue(arr)
    return arr.filter((item,
        index) => arr.indexOf(item) === index)
}

export const randomEntry = (arr) =>{
    return arr[randomNumber(0, arr.length-1)]
}

/**
 * 
 * Takes an array and returns a map that groups the array items into categories.
 * 
 * To group the items, the field 'category' of the item is used.
 * 
 * Items without categorie are grouped into the category 'Other'
 * 
 * @param {*} arr 
 * @returns 
 */
export const getCategoriesMap = (arr) => {
    const categories = {}
    arr.forEach((item)=>{
        let category = item.category || 'Other'
        categories[category] = categories[category] || []
        categories[category].push(item)
    })
    return categories
}

export const toQueryParamsStr = (arr) => {
    requireValue(arr)

    if (arr.length === 0) {
        return ''
    }

    if (arr.length % 2 !== 0) {
        throw new Error("arr.length must be even number")
    }

    let paramsStr = '?'

    arr.forEach((item, idx)=>{
        const isKey = idx % 2 === 0

        if (item === null) {
            throw new Error("arr item cannot be null")
        }

        if (isKey && idx > 0) {
            paramsStr = paramsStr + '&'
        }

        if (!isKey) {
            paramsStr = paramsStr + '='
        }

        paramsStr = paramsStr + encodeURIComponent(item)
    })

    return paramsStr

}

export const filterArray = (items, query, fields) => {
    if (!query) {
        return items;
    }

    const queryLowerCase = query.toLowerCase()

    return items.filter(i=>{
        for (const field of fields) {
            const val = getFieldByPath(i, field)
            if (!val) {
                continue;
            }
            if (val.toLowerCase().includes(queryLowerCase)) {
                return true
            }
        }
        return false
    })
}

/**
 * 
 * Modifies the given items that match the given query using the given modifierFnc.
 * 
 * An item matches if the query matches the given fields
 * 
 * 
 * @param {*} items 
 *      Required
 * @param {*} query 
  *     Can ben null
 * @param {*} fields 
 *      Required
 * @param {*} modifyFnc 
  *      Required
 * @returns 
 */
export const mapArrayAndModifyMatching = (items, query, fields, modifyFnc) => {
    requireValue(items)
    requireValue(fields)
    requireValue(modifyFnc)

    if (!query) {
        return items;
    }

    const queryLowerCase = query.toLowerCase()

    return items.map(i=>{
        for (const field of fields) {
            const val = i[field]
            if (!val) {
                continue;
            }
            if (val.toLowerCase().includes(queryLowerCase)) {
                return modifyFnc(i)
            }
        }
        return i
    })
}

/**
 * 
 * Modifies the given items that do not match the given query using the given modifierFnc.
 * 
 * An item matches if the query matches the given fields
 * 
 * 
 * @param {*} items 
 *      Required
 * @param {*} query 
  *     Can ben null
 * @param {*} fields 
 *      Required
 * @param {*} modifyFnc 
  *      Required
 * @returns 
 */
 export const mapArrayAndModifyNonMatching = (items, query, fields, modifyFnc) => {
    requireValue(items)
    requireValue(fields)
    requireValue(modifyFnc)

    if (!query) {
        return items;
    }

    const queryLowerCase = query.toLowerCase()

    return items.map(i=>{
        for (const field of fields) {
            const val = i[field]
            if (!val) {
                continue;
            }
            if (val.toLowerCase().includes(queryLowerCase)) {
                return i
            }
        }
        return modifyFnc(i)
    })
}

export const createArrayOfSize = (size) => {
    requireValue(size)
    if (size === 0) {
        return []
    }
    //@ts-ignore: no idea, typescript migration
    return Array(size).fill().map((x,i)=>i)
}

export const createIntArray = (size) => {
    requireValue(size)
    if (size === 0) {
        return []
    }
    //@ts-ignore: no idea, typescript migration
    return Array(size).fill().map((x,i)=>i)
}

export const listFromMap = (map) => {
    requireValue(map)
    return Object.keys(map).map(k=>{
        return {
            key: k,
            ...map[k]
        }
    })
}

export const collectArrayField = (arr, field) => {
    requireValue(arr)
    requireValue(field)
    const res = []
    arr.filter(i=>(i[field]||null)!==null).reduce((arr, item) => {
        arr.push(...item[field]);
        return arr;   
    }, res)
    return res
}

export const withoutDuplicates = (arr) => {
    requireValue(arr)
    //@ts-ignore: no idea, typescript migration
    return [...new Set(arr)]
}

export const pushOneOrMany = (targetArr, obj) => {
    if (Array.isArray(obj)) {
        targetArr.push(...obj)
    } else {
        targetArr.push(obj)
    }
}

export const shuffle = (array) => {
    let currentIndex = array.length,  randomIndex;
    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
}

export const arrayToMap = (array, keyField) => {
    requireValue(array)
    requireValue(keyField)
    return array.reduce((acc, value) => (acc[value[keyField]] = value, acc), {});
}

export const getNextItem = (array, currentItem) => {
    requireValue(array)
    requireValue(currentItem)

    // Find the index of the current item
    const currentIndex = array.indexOf(currentItem);

    // Check if the current item is not found or it is the last item
    if (currentIndex === -1 || currentIndex === array.length - 1) {
        return null;
    }

    // Return the next item
    return array[currentIndex + 1];
}

export default { isEmpty, isNotEmpty };
